/* Custom styles for the date range picker */
.DateRangePickerInput {
    background-color: #1a202c;
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
  
  .DateInput_input {
    color: white;
  }
  
  .DayPicker {
    background-color: #1a202c;
  }
  
  .CalendarDay__selected_span {
    background: #ed8936;
    border: 1px double #ed8936;
  }
  
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:hover {
    background: #f6ad55;
    border: 1px double #f6ad55;
  }

  