span[class^="dot-"]{
    opacity: 0;
  }
  .dot-one{
    animation: dot-one 1s infinite linear
  }
  .dot-two{
    animation: dot-two 1s infinite linear
  }
  .dot-three{
    animation: dot-three 1s infinite linear
  }
  @keyframes dot-one{
    0%{
      opacity: 0;
    }
    15%{
      opacity: 0;
    }
    25%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes dot-two{
    0%{
      opacity: 0;
    }
    25%{
      opacity: 0;
    }
    50%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }
  
  @keyframes dot-three{
    0%{
      opacity: 0;
    }
    50%{
      opacity: 0;
    }
    75%{
      opacity: 1;
    }
    100%{
      opacity: 1;
    }
  }