.loader-clockwise {
    animation: spin 1.8s linear infinite;
}

.loader-anticlockwise{
    animation: reverseSpin 0.7s linear infinite;
}

.outer-wrapper {
    border: 2px solid transparent;
    border-top: 2px solid #b5772f;
    /* Blue */
    border-left: 2px solid #b5772f;
    /* Blue */
}

.inner-wrapper {
    border: 2px solid transparent;
    border-top: 2px solid #b5772f;
    /* Blue */
    border-left: 2px solid #b5772f;
    /* Blue */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes reverseSpin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(-360deg);
    }
}